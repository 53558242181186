import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinkButton from './LinkButton';
import { Button, fabClasses } from '@mui/material';

const getEgyptianExpiry = (eventDate) => {
  const expiryDate = new Date(eventDate);
  expiryDate.setHours(23, 59, 59, 999); // Set to 11:59:59 PM on event day
  return expiryDate;
};

const eventDetails = {
  formulaE: {
    name: "Disco Misr at Babbo’s Taverna (Mesca Beach, Soma Bay)",
    date: 'WEDNESDAY, April 2, 2025',
    link: 'https://eg.dyneapp.io/reservations/4cec8b50-05b7-11f0-9f5e-ebd1d85b6e09',
    sublinktext: true,
    expiry: getEgyptianExpiry('2025-04-02')
  },
  e7kkySummit: {
    name: 'Disco Misr at Helipad Dubai',
    date: 'SATURDAY, April 5, 2025 - SUNDAY, April 6, 2025',
    link: 'https://tickit.co/events/6VxmPdDO4OWPEgDfrRng',
    expiry: getEgyptianExpiry('2025-04-06')
  }
};

const MaterialModal = ({ open, handleClose }) => {
  const [modalWidth, setModalWidth] = useState(400);

  useEffect(() => {
    const updateWidth = () => {
      setModalWidth(window.innerWidth < 400 ? window.innerWidth * 0.9 : 400);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: '#1e1e1e',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleEventClick = (event) => {
    console.log("Checking event:", event.name, "Expiry:", event.expiry, "Current Time:", new Date());
    if (event.expiry && new Date() > event.expiry) return;
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: 'Button',
        event_label: event.name,
        value: 1
      });
    }
    window.open(event.link, '_blank');
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{ zIndex: 9999 }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: 'center',
              marginBottom: 2,
              fontWeight: 'bold',
              color: 'white'
            }}
          >
            Upcoming Events
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            {Object.values(eventDetails).map(event => (
              (event.expiry && new Date() > event.expiry) ? null : (
                <Button
                  key={event.name}
                  variant="contained"
                  sx={{
                    background: 'white',
                    color: 'black',
                    width: '100%',
                    fontSize: '16px',
                    ':hover': {
                      background: 'linear-gradient(45deg, #A09ADC 20%, rgba(183, 232, 207, 1) 60%)',
                      fontWeight: 'bold',
                    },
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                  onClick={() => handleEventClick(event)}
                >
                  {event.name}
                  <br />
                  {
                    event.sublinktext &&
                    <span style={{ fontFamily: "'Tajawal', 'Cairo', 'Noto Kufi Arabic', sans-serif", fontSize: '12px', fontStyle:'normal', textTransform:"none", fontStyle:'italic'}}>
                      For reservations DM <span style={{fontWeight:700}}>
                        @babbostaverna
                        </span>
                    </span>
                  }
                  <span style={{ fontFamily: "'Tajawal', 'Cairo', 'Noto Kufi Arabic', sans-serif", fontSize: '12px' }}>
                    {event.date}
                  </span>
                </Button>
              )
            ))}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default MaterialModal;
