import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation, Navigate} from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Navbar from './Navbar';
import Footer from './Footer';
import HamburgerNav from './HamburgerNav.js';
import LivePictures from './Pages/LivePictures.js';
import StudioPictures from './Pages/StudioPictures.js';
// import Releases from './Pages/Releases.js';
import PressCoverage from './Pages/PressCoverage.js';
import Videos from './Pages/Videos.js';
// import UpcomingEvent from './Pages/UpcomingEvents.js';
// import Shop from './Pages/Shop.js';
// import Product from './Pages/Product.js';
// import CommandCenter from './CommandCenter.js';
// import FloatingControl from './FloatingControl.js'
import ScrollToTop from './Pages/components/ScrollToTop.js';
import NotFound from './Pages/components/NotFound.js';
// import ShopContextLayout from './layouts/ShopContextLayout.js';
// import Cart from './Pages/Cart.js';
import getOS from './Pages/components/GetOS.js';
import BackToTop from './Pages/components/BackToTop.js'
import { HelmetProvider } from "react-helmet-async";



function App() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const os = getOS();
  let scrollSpeed = os === 'Windows' ? .175 : .1;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = (event) => {
      if (Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
        event.preventDefault();
        window.scrollBy({
          top: event.deltaY * scrollSpeed,  // Reducing vertical scroll speed by 50%
          behavior: 'auto'
        });
      }
      // No need for event.preventDefault here to preserve horizontal scroll
    };

    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => window.removeEventListener('wheel', handleScroll);
  }, []);


  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Home - Disco Misr';
        break;
      case '/about':
        document.title = 'About - Disco Misr';
        break;
      case '/bookings':
        document.title = 'Bookings - Disco Misr';
        break;
      case '/LivePictures':
        document.title = 'Live Pictures - Disco Misr';
        break;
      case '/Releases':
        document.title = 'Releases - Disco Misr';
        break;
      default:
        document.title = 'Disco Misr';
    }
  }, [location]);

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-4JMM7Q8M29";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-4JMM7Q8M29');
    `;
    document.head.appendChild(script2);
  }, []);


  return (
    <HelmetProvider>
      <div className="container">
        <ScrollToTop />
        {/* <FloatingControl/> */}
        {isMobile ? <HamburgerNav /> : (location.pathname === '/' || location.pathname === '/home' || location.pathname === '/bookings' || location.pathname === '/UpcomingEvents' ? <Navbar /> : <HamburgerNav />)}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/LivePictures" element={<LivePictures />} />
          <Route path="/bookings" element={<Home scrollToForm />} />
          <Route path="/StudioPictures" element={<StudioPictures />} />
          <Route path="/Releases" element={<Navigate to="/PressCoverage" replace />} />
          <Route path="/PressCoverage" element={<PressCoverage />} />
          <Route path="/Videos" element={<Videos />} />
          {/* Add the wildcard route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <BackToTop />
      </div>
    </HelmetProvider>

  );
}

export default App;
