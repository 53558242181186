import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import './stylesheets/PressCoverage.css';
import Keifykeda from './coverage/kefiykeda.jpg'; import Mwawel from './coverage/mawawel.jpg'; import FeEineh from './coverage/feeineh.jpg'; import AnaNegm from './coverage/ananegm.jpg'; import Mabsoota from './coverage/mabsoota.jpg'; import AlfaLeila from './coverage/alfleila.jpg'; import Ahlawahda from './coverage/ahlawahda.jpg';
import Moshakes from './coverage/moshakes.png';
import Etisalat from './coverage/etisalat.jpg';
import Redbull from './coverage/redbull.jpg';
import AnghamiIcon from './a-svg-icons/anghami.svg';
import SpotifyIcon from './a-svg-icons/spotify.svg';
import YoutubeIcon from './a-svg-icons/youtube.svg';
import Draggable from 'react-draggable';
import { useLocation } from 'react-router-dom';
import FaatMaat from './remixes/faatmaat.jpg';
import Disco5 from './remixes/5disco.jpg';
import Agabdisco7 from './remixes/agabdisco7.jpg';
import Cassettetrip from './remixes/cassettetrip.jpg';
import Leferagtany from './remixes/leferagtany.jpg';
import Qabldisco from './remixes/qabldisco.jpg';
import FirstAlbum from './remixes/firstalbumdisco.jpg';
import { Helmet } from 'react-helmet-async';
import newbgImg from './assets/disco-misr-new-album.png';


const originals = [
    {
        title: "Keda Ray2a",
        src: 'https://i.ytimg.com/vi/EG9kyCao5pM/maxresdefault.jpg',
        links: [
            // { name: 'Anghami', url: 'https://open.anghami.com/w915WVn8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://www.youtube.com/watch?v=EG9kyCao5pM&pp=ygUSZGlzY28gbWlzciByZWRidWxs', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/3UNWQ32OPdRYB6H5wEeWQ1', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Mwawel",
        src: Mwawel,
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/w915WVn8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/o30X0SOj39U?si=FYc-mlZFEHuxqoLK', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/3F6gOpdaoai87hSQhVovVm?si=8pD9ljSgS52OO-WUwbyENg', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Fe Eineh",
        src: FeEineh,
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/sgfM6lf8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/voa8jFUA7Q4?si=HmhTFEfUhU8Gwshf', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/1iXs29VLepoUwk7iCVJ8KG?si=wdBPHD6OR9OeuzlMzfGoTQ', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Keify Keda",
        src: Keifykeda,
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/bzt5vlk8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/s_C0CDkP3Cc?si=LUCzWjY03UBel12_', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/3yWSjxKQMpa7hXcmVElZgX?si=Z7pHF__oTHOoFGc-l-oxuQ', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "A7la Wahda",
        src: Ahlawahda,
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/kFAWGrl8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/0i6mRThBxi0?si=gNCzPnSRCTukzvZB', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/6zUTQHFa3gsmWyRxZzYe4e?si=HNTgApd9RjemOucAgp03yw', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Mabsota",
        src: Mabsoota,
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/5Npiimc8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/eWlQw2Hoa-A?si=Gx085YczNg7H6aCX', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/44FoCsba73a8sBxtsr0I4c?si=1oapoyMfSTCORwSUwNPIvw', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "MOSHAKES Ft. Ahmed Basyoni",
        src: Moshakes,
        links: [
            { name: 'Anghami', url: 'https://play.anghami.com/song/1023974701', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/IfX_o-4ENOw?si=OlU2vOpnWYmqJZ_W', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/4piJI1AwszO14uDRy7oVlN?si=vacWtUlWQMGjT6Lh82Vt9A&context=spotify%3Aalbum%3A7AKinihiQ29C0YALaUX9Df', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Demagh Tanya (Etisalat Original)",
        src: Etisalat,
        links: [
            // { name: 'Anghami', url: '', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://www.youtube.com/watch?v=Im14fKpboQk&ab_channel=e%26Egypt', icon: YoutubeIcon },
            // { name: 'Spotify', url: '', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "B3d Ezn Nehayet El Alam Ft. Hana Ghoniem",
        src: 'https://is1-ssl.mzstatic.com/image/thumb/Music113/v4/03/38/3f/03383f80-8c94-2ddf-2871-1bbf23aa3ca5/Ba_d_Ezn_Nehayet_El_Alam.jpg/600x600bf-60.jpg',
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/iZyNar57wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/6QID9Zs5hmQ?si=olngjahfRTQPbvtM', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/5MCZwHNzLusveZS8Dyoyw6?si=ckLCjkerTKW-mUWDuFop-Q&context=spotify%3Aalbum%3A2s41CW6csEsALEo88Klbpy', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ],
    },
];

const remixes = [
    {
        title: "",
        src: newbgImg,
        links: [
        ]
    },
    {
        title: "",
        src: Leferagtany,
        links: [
        ]
    },
    {
        title: "",
        src: Agabdisco7,
        links: [
        ]
    },
    {
        title: "",
        src: Qabldisco,
        links: [
        ]
    },
    {
        title: "",
        src: Disco5,
        links: [
        ]
    },
    {
        title: "",
        src: Redbull,
        links: [
        ]
    },
    {
        title: "",
        src: Cassettetrip,
        links: [
        ]
    },

    {
        title: "",
        src: FaatMaat,
        links: [
        ]
    },

    {
        title: "",
        src: FirstAlbum,
        links: [
        ]
    }
]

const dataremixes = [
    {
        title: "Ana Negm",
        src: AnaNegm,
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/sgfM6lf8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/Bker4Yz71v4?si=x1gx0luaWLpInjRz', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/74j5S5Nd8ENeBpONrtChsK?si=UMbW8HnWTri9FYmnSsdqpw', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Alf Leila W Leila",
        src: AlfaLeila,
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/bbC4Gpa8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/wSRmqAwvvZ0?si=SZgZLkv7eXdIaOtY', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/01rqnaEjqaP6luRtxdcaz9?si=9WuEaEnhTUqf__odrwOsSQ', icon: SpotifyIcon },
            // { name: 'Apple Music', url: 'https://placeholder.apple.music', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Cherophobia Remix",
        src: 'https://i.ytimg.com/vi/2GcU37-IxdY/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLBz8RZFKmS9aNDw4eXMM5cxOzjDqg',
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/oXI4d787wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/pjw2emndPgE?si=ToJgvqGiQAFogP0l', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/0ljcm1dRUz95DKPXInCfSZ?si=1yEDQMJgRfeaeuA_nWYHCw&context=spotify%3Aalbum%3A6Ed3MhkyiuEsg8OCAIIN6x', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    },
    {
        title: "El Donia Resha F Hawa Ft. Sherine Abdo",
        src: 'https://i.ytimg.com/vi/2GcU37-IxdY/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLBz8RZFKmS9aNDw4eXMM5cxOzjDqg',
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/QSzpuDh8wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/2GcU37-IxdY?si=oBy4qPP8_8uf8Cio', icon: YoutubeIcon },
            { name: 'Spotify', url: 'https://open.spotify.com/track/5zPaNHm3D7H4k6CnMM1EUD?si=VgDNqputR3yR_wXz-1YA6Q&context=spotify%3Aalbum%3A6OT8AOdsKR9O7dLzaF6St5', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Roh El Fouad (Dinga Dinga) Remix",
        src: 'https://artwork.anghcdn.co/webp/?id=80319230&size=640',
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/gkWOEAe9wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: '', icon: YoutubeIcon },
            { name: 'Spotify', url: '', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Khaled Hammad (Africano) Remix",
        src: 'https://artwork.anghcdn.co/webp/?id=80319230&size=640',
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/KhZ0hhk9wQb', icon: AnghamiIcon },
            //   { name: 'YouTube', url: '', icon: YoutubeIcon },
            //   { name: 'Spotify', url: '', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Talaat Zein _ Taala Remix",
        src: 'https://artwork.anghcdn.co/webp/?id=80319230&size=640',
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/lrvOjeo9wQb', icon: AnghamiIcon },
            //   { name: 'YouTube', url: '', icon: YoutubeIcon },
            //   { name: 'Spotify', url: '', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Omar Khairat (Zy ma heya hebaha) Remix",
        src: 'https://artwork.anghcdn.co/webp/?id=80319230&size=640',
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/6766msH9wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: '', icon: YoutubeIcon },
            { name: 'Spotify', url: '', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Mohamed Abdel Moteleb (Mabyes’lsh Alaya) Remix",
        src: 'https://artwork.anghcdn.co/webp/?id=80319230&size=640',
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/ynrRRTR9wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: '', icon: YoutubeIcon },
            { name: 'Spotify', url: '', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    },
    {
        title: "Abu Ft. Yousra (3 Dakkat) Remix",
        src: "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/96/58/c7/9658c782-85b4-1d51-8472-643c54017c59/6221117019882.jpg/1200x1200bb.jpg",
        links: [
            { name: 'Anghami', url: 'https://open.anghami.com/5oLq1O19wQb', icon: AnghamiIcon },
            { name: 'YouTube', url: 'https://youtu.be/NtOfR2QmA28?si=BS84piMkAAcb7MZf', icon: YoutubeIcon },
            { name: 'Spotify', url: '', icon: SpotifyIcon },
            //{ name: 'Apple Music', url: '', icon: AppleMusicIcon }
        ]
    }
]

const articles = [
    {
        title: "SceneNoise",
        subtitle: "Disco Misr x Tul8te Drop New Track 'Fe Eineh'",
        src: 'https://scenenow.com/Content/Admin/Uploads/Articles/ArticlesMainPhoto/67106/30b6caa9-ca0c-4fe8-905f-8bcf2d9cedb2.jpg',
        links: 'https://scenenoise.com/New-Music/Disco-Misr-x-Tul8te-Drop-New-Track-Fe-Eineh?utm'
    },
    {
        title: "Egyptian Streets",
        subtitle: "Disco Misr’s Track for Adidas Campaign",
        src: 'https://egyptianstreets.com/wp-content/uploads/2023/05/main-adidas-pabloed.png',
        links: 'https://egyptianstreets.com/2023/05/03/disco-misr-track-for-adidas-regional-campaign-is-testament-to-egypts-burgeoning-music-scene/?utm'
    },
    {
        title: "ahramonline",
        subtitle: "Egyptian top rapper Wegz releases Keify Keda music video",
        src: 'https://english.ahram.org.eg/Media/News/2021/10/23/41_2021-637706114387563892-756.jpg',
        links: 'https://english.ahram.org.eg/News/436391.aspx'
    },
    {
        title: "cairo360",
        subtitle: "Disco Misr, Abdel Halim Hafez Tribute, Egyptian...",
        src: 'https://assets.cairo360.com/app/uploads/2025/01/21/hey-dd-875x323.jpg',
        links: 'https://www.cairo360.com/article/city-life/weekend-guide-disco-misr-abdel-halim-hafez-tribute-egyptian-mawlawia-cairo-international-book-fair-maadi-garage-sale-more/'
    },
    {
        title: "whatsonsaudiaarabia",
        subtitle: "Lil Baby, Disco Misr and Miami Band to perform at the Jeddah E-Prix",
        src: 'https://whatsonsaudiarabia.com/wp-content/uploads/2025/01/jeddah-eprixconcerts.jpg',
        links: 'https://whatsonsaudiarabia.com/2025/01/jeddah-e-prix-2025-headliners/'
    },
    {
        title: "SceneNoise",
        subtitle: "Disco Misr North American Tour with Sharmoofers",
        src: 'https://scenenoise.com/Content/Articles/Big_image/ac236a15-d568-4b77-a710-7073eb9b6b75.jpg',
        links: 'https://scenenoise.com/News/Sharmoofers-Disco-Misr-Announce-North-America-Tour?utm'
    },
    {
        title: "CairoScene",
        subtitle: "Disco Misr & Loush Will Perform in Riyadh on October 5th",
        src: 'https://cairoscene.com/Content/Admin/Uploads/Articles/ArticlesMainPhoto/1158570/808b38c4-7778-44f7-b9a5-3fb9a50a2375.jpg',
        links: 'https://cairoscene.com/Noise/Disco-Misr-Loush-Will-Perform-in-Riyadh-on-October-5th'
    },

];

const RestComponent = ({ src, Links, imgposition }) => {
    const [showLinks, setShowLinks] = useState(false);

    return (
        <>
            <div
                className='press-container'
                style={{
                    backgroundImage: `url(${src})`,
                    backgroundPosition: imgposition,
                    touchAction: 'pan-y' // Ensures vertical scrolling
                }}
            >
                <div className="linkgrid">
                    <a
                        className="description"
                        onTouchStart={(e) => {
                            setShowLinks(!showLinks);
                        }}
                        onClick={() => setShowLinks(!showLinks)}
                    >
                        LINKS<br />▼
                    </a>
                    <div
                        className={`linkslayout ${showLinks ? 'open' : 'closed'}`}
                    >
                        {Links?.map((link, index) => (
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                onTouchStart={(e) => {
                                    window.open(link.url, '_blank');
                                }}
                                onClick={(e) => {
                                    window.open(link.url, '_blank');
                                }}
                                href={link.url}
                                key={index}
                                className='linkicon'
                                style={{ zIndex: 9999, position: 'relative' }}
                            >
                                <img src={link.icon} alt="Link Icon" />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};


const ArticleComponent = ({ src, title, subtitle, links, isMusic = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleArticle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            id='musiccoverage'
            className="articlecoverage"
            onClick={toggleArticle}
            style={{
                backgroundImage: `url(${src})`, // Keep image background styling inline
            }}
        >
            <div className="article-text">
                <h3>{title}</h3>
                <p>{subtitle}</p>
            </div>

            {isOpen && (
                <div className="article-details">
                    {
                        !isMusic ?
                            <>
                                <a href={links} target="_blank" rel="noopener noreferrer" className="read-more">
                                    Read more
                                </a>
                            </>
                            :
                            <>
                                <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                                    {links?.map((link, index) => (
                                        <a
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            onTouchStart={(e) => {
                                                e.preventDefault();
                                                window.open(link.url, '_blank');
                                            }}
                                            href={`${link.url}`}
                                            key={index}
                                            className='linkicon'
                                            id='musiclinkicon'
                                        >
                                            <img src={link.icon} alt="Link Icon" />
                                        </a>
                                    ))}
                                </div>

                            </>
                    }

                </div>
            )}
        </div>
    );
};


export default function PressCoverage() {
    let scrollSpeed = 1;
    const location = useLocation();

    useEffect(() => {
        const handleScroll = (event) => {
            if (Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
                event.preventDefault();
                window.scrollBy({
                    top: event.deltaY * scrollSpeed,  // Reducing vertical scroll speed by 50%
                    behavior: 'auto'
                });
            }
            // No need for event.preventDefault here to preserve horizontal scroll
        };

        window.addEventListener('wheel', handleScroll, { passive: false });

        return () => window.removeEventListener('wheel', handleScroll);
    }, []);

    useEffect(() => {
        if (location.hash === "#articles") {
            const articlesSection = document.getElementById("articles");
            if (articlesSection) {
                articlesSection.scrollIntoView({ behavior: "smooth" });
            }
        }
        if (location.hash === "#remixes") {
            const articlesSection = document.getElementById("remixes");
            if (articlesSection) {
                articlesSection.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);


    return (
        <>
            <Helmet>
                <title>Disco Misr - Press Coverage</title>
                <meta name="description" content="Read about Disco Misr in the media. Explore articles, interviews, and press features covering our music and journey." />
                <meta property="og:title" content="Disco Misr - Press Coverage" />
                <meta property="og:description" content="Discover what the press is saying about Disco Misr. Check out our latest media features and interviews." />
                <meta property="og:image" content="https://discomisr.com/studiopics/disco-misr-pool-photo-session.jpg" />
                <meta property="og:url" content="https://discomisr.com/PressCoverage" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="PressWrapper">
                <Typography variant="h4" className="page-title" fontFamily={'Raleway'} fontSize={32} style={{}}>
                    Originals
                </Typography>
                <div className='originals'>
                    {originals.map((song, index) => (
                        index < 8 ?
                            <Draggable key={index}>
                                <RestComponent src={song.src} Links={song.links} />
                            </Draggable> :
                            <RestComponent src={song.src} Links={song.links} imgposition={'20px -60px'} />
                    ))}
                </div>
                <Typography id="remixes" variant="h4" className="page-title" fontFamily={'Raleway'} fontSize={32} style={{ padding: '2.5% 0%' }}>
                    Remixes
                </Typography>
                <div className='wrapdata' >
                    {remixes.map((remix, index) => (
                        <ArticleComponent
                            key={index}
                            title={remix.title}
                            src={remix.src}
                            links={remix.links}
                            isMusic={true}
                        />
                    ))}
                </div>
                <Typography id="articles" variant="h4" className="page-title" fontFamily={'Raleway'} fontSize={32} style={{ padding: '2.5% 0%' }}>
                    Press
                </Typography>
                <div className='wrapdata' >
                    {articles.map((article, index) => (
                        <ArticleComponent
                            key={index}
                            title={article.title}
                            subtitle={article.subtitle}
                            src={article.src}
                            links={article.links}
                        />
                    ))}
                </div>
            </div>
        </>

    );
}
