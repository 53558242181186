import React, { useEffect, useState } from 'react';
import './stylesheets/StudioPics.css';
import CenterLogo from './assets/logo.png';
import ClickableImage from './components/ClickableImage';
import { Helmet } from 'react-helmet-async';

const StudioPic1 = "/studiopics/meet-disco-misr.png";
const StudioPic2 = "/studiopics/disco-misr-photo-session.png";
const StudioPic3 = "/studiopics/disco-misr-members.png";
const StudioPic4 = "/studiopics/disco-misr-photo-session-new.png";
const StudioPic5 = "/studiopics/disco-misr-schady.png";
const StudioPic6 = "/studiopics/disco-misr-fashion.png";
const StudioPic7 = "/studiopics/disco-misr-amr-emad.png";
const StudioPic8 = "/studiopics/disco-misr-mostafa-sherif.png";
const StudioPic9 = "/studiopics/disco-misr-all-members.png";
const StudioPic10 = "/studiopics/disco-misr-photo-dj.png";
const StudioPic11 = "/studiopics/disco-misr-2023-photo.png";
const StudioPic112 = "/studiopics/disco-misr-team-dj.png";
const StudioPic12 = "/studiopics/disco-misr-mostafa-photo.png";
const StudioPic13 = "/studiopics/disco-misr-schady-visuals.png";
const StudioPic14 = "/studiopics/disco-misr-schady-2025.jpg";
const StudioPic15 = "/studiopics/disco-misr-2025-photo-session.jpg";
const StudioPic16 = "/studiopics/disco-misr-team.jpg";
const StudioPic17 = "/studiopics/disco-misr-pool-photo-session.jpg";

export default function StudioPictures() {
    const [scrollY, setScrollY] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const transitionRatio = Math.min(scrollY / 1000, 10); // Adjust value to control the speed of transition
    let val = 0.25
    let rotationAngle;

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    if (windowWidth < 768) {
        rotationAngle = scrollY * 0.70; // Adjust this value to control the speed of rotation
    }
    else {
        rotationAngle = scrollY * 0.25;
    }

    if (rotationAngle > 350) {
        rotationAngle = 360
    }
    const bigPicTransform = windowWidth < 768 ? '' : `scale(${1 - transitionRatio * 0.70}) translate(${transitionRatio * -1700}px, ${transitionRatio * 1175}px)`;
    const images = [StudioPic1, StudioPic2, StudioPic3, StudioPic4, StudioPic5, StudioPic6, StudioPic7, StudioPic8, StudioPic9, StudioPic10, StudioPic11, StudioPic112, StudioPic12, StudioPic13, StudioPic14, StudioPic15, StudioPic16, StudioPic17];

    return (
        <>
            <Helmet>
                <title>Disco Misr - Studio Pictures | صور استوديو ديسكو مصر</title>
                <meta name="description" content="Explore professional studio photos of Disco Misr, capturing our creative process and style. استكشف صور الاستوديو الاحترافية لديسكو مصر، والتي تعرض عمليتنا الإبداعية وأسلوبنا الفريد." />
                <meta property="og:title" content="Disco Misr - Studio Pictures | صور استوديو ديسكو مصر" />
                <meta property="og:description" content="Exclusive behind-the-scenes studio photos from Disco Misr. صور حصرية من وراء الكواليس لديسكو مصر." />
                <meta property="og:image" content="https://discomisr.com/studiopics/disco-misr-2025-photo-session.jpg" />
                <meta property="og:url" content="https://discomisr.com/StudioPictures" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Disco Misr, Studio Pictures, Egyptian DJs, Live Music, ديسكو مصر، صور استوديو، موسيقى حية" />
                <link rel="canonical" href="https://discomisr.com/StudioPictures" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "ImageGallery",
                        "name": "Disco Misr | Studio Pictures",
                        "image": images.map((src) => ({
                            "@type": "ImageObject",
                            "url": `https://discomisr.com${src}`,
                            "caption": `Description for ${src.split("/").pop()}`
                        }))
                    })}
                </script>
            </Helmet>

            <div id='StudioSection' style={{ marginBottom: '1%' }}>
                <div id='StudioWrapper'>
                    <img
                        id='BigPic'
                        src={StudioPic1}
                        alt='Who are Disco Misr? | Studio Session with Disco Misr'
                        style={{
                            transform: bigPicTransform,
                            opacity: `${1 - transitionRatio}`,
                            transition: 'transform 0.3s ease, opacity 0.3s ease',
                        }}
                    />
                    <ClickableImage id='CenterLogo' src={CenterLogo} style={{ position: 'absolute', zIndex: 9998, width: 125, top: 150 }} alt='Disco Misr Logo' />
                </div>
                <div>
                    <div className="row">
                        <div className="column">
                            <img
                                src={StudioPic17}
                                alt='Meet the Team | Studio Session with Disco Misr'
                                id='StudioPic17-768'
                            />
                            <img src={StudioPic14} alt='Disco Misr Tickets on Discomisr.com | Studio Session with Disco Misr' />
                            <img
                                src={StudioPic16}
                                alt='Studio Session with Disco Misr'
                                id='StudioPic16-768'
                            />
                            <img
                                src={StudioPic13}
                                alt='Studio Session with Disco Misr'
                                id='StudioPic13-768'
                            />
                            <img
                                src={StudioPic2}
                                alt='Amr Emad Mostafa Sherif Schady Wasfy | Studio Session with Disco Misr'
                                id='StudioPic2-768'
                            />
                            <img src={StudioPic1} alt='Read More about Disco Misr | Studio Session with Disco Misr' style={{ opacity: `${transitionRatio}`, transition: 'opacity 0.3s ease' }} />
                            <img src={StudioPic4} alt='Disco Meet Disco Misr | Studio Session with Disco Misr' />
                            <img src={StudioPic7} alt='Music and Visuals with Disco Misr | Studio Session with Disco Misr' />
                            <img src={StudioPic8} alt='Photo Session | Studio Session with Disco Misr' />
                            <img src={StudioPic10} alt='Photo Session | Studio Session with Disco Misr' />
                        </div>

                        <div className="column">
                            <img src={StudioPic15} alt='Amr Emad Mostafa Sherif Schady Wasfy | Studio Session with Disco Misr' />
                            <img
                                src={StudioPic12}
                                alt='Meet the Team | Studio Session with Disco Misr'
                                id='StudioPic12-768'
                            />
                            <img
                                src={StudioPic6}
                                alt='DJ in Egypt | Studio Session with Disco Misr'
                                style={{
                                    transform: `rotate(${rotationAngle}deg)`,
                                    transition: 'transform 0.3s ease'
                                }}
                                id='StudioPic6-768'
                            />

                            <img src={StudioPic9} alt='Find more on Discomisr.com | Studio Session with Disco Misr' />
                            <img
                                src={transitionRatio > 2.2 ? StudioPic112 : StudioPic11}
                                style={{ opacity: `${1 - transitionRatio * 0.1}`, transition: 'opacity 0.3s ease' }}
                                alt='Meet the Team | Studio Session with Disco Misr'
                            />
                            <img src={StudioPic3} alt='Amr Emad Mostafa Sherif Schady Wasfy | Studio Session with Disco Misr' />
                            <img src={StudioPic5} alt='Amr Emad Mostafa Sherif Schady Wasfy | Studio Session with Disco Misr' />
                        </div>

                        <div className="column">
                            <img
                                src={StudioPic16}
                                alt='Studio Session with Disco Misr'
                                className="StudioPic-thirdColumn"
                            />
                            <img
                                src={StudioPic17}
                                alt='Meet the Team | Studio Session with Disco Misr'
                                className="StudioPic-thirdColumn"
                            />
                            <img
                                src={StudioPic6}
                                alt='DJ in Egypt | Studio Session with Disco Misr'
                                style={{
                                    transform: `rotate(${rotationAngle}deg)`,
                                    transition: 'transform 0.3s ease',
                                }}
                            />
                            <img
                                src={StudioPic12}
                                alt='Meet the Team | Studio Session with Disco Misr'
                                className="StudioPic-thirdColumn"
                            />
                            <img
                                src={StudioPic13}
                                alt='Studio Session with Disco Misr'
                                className="StudioPic-thirdColumn"
                            />
                            <img
                                src={StudioPic2}
                                alt='Amr Emad Mostafa Sherif Schady Wasfy | Studio Session with Disco Misr'
                                className="StudioPic-thirdColumn"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
