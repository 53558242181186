import { useEffect, useRef, useState } from "react";
import { storage } from "../firebaseConfig";
import { Button, IconButton, CircularProgress } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import "./stylesheets/Videos.css";
import { fetchThumbnails, fetchVideoURLs } from "../utilties/FirebaseFunctions";
import { motion, AnimatePresence } from "framer-motion";

const videos = [
  "video3", "video2", "video1", "video4", "video5",
  "video6", "video7", "video8", "video9", "video10",
  "video11", "video12", "video13", "video14", "video15"
];

const Videos = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lowQualityURL, setLowQualityURL] = useState(null);
  const [highQualityURL, setHighQualityURL] = useState(null);
  const [thumbnails, setThumbnails] = useState({});
  const [downloadProgress, setDownloadProgress] = useState(null);

  useEffect(() => {
    const loadVideos = async () => {
      const videoName = videos[currentIndex];
      const { lowQualityURL, highQualityURL } = await fetchVideoURLs(storage, videoName);
      setLowQualityURL(lowQualityURL);
      setHighQualityURL(highQualityURL);
    };

    loadVideos();
  }, [currentIndex, videos]);

  useEffect(() => {
    const loadThumbnails = async () => {
      const urls = await fetchThumbnails(storage, videos);
      setThumbnails(urls);
    };

    loadThumbnails();
  }, [videos]);

  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % videos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + videos.length) % videos.length);
  };

  const handleDragEnd = (event, info) => {
    const offset = info.offset.x;
    const width = containerRef.current.clientWidth / videos.length;

    if (offset < -width / 2 && currentIndex < videos.length - 1) {
      setCurrentIndex((prev) => (prev + 1) % videos.length);
    } else if (offset > width / 2 && currentIndex > 0) {
      setCurrentIndex((prev) => (prev - 1 + videos.length) % videos.length);
    }
  };

  const handleDownload = async () => {
    if (!highQualityURL) return;

    try {
      setDownloadProgress(0); // Show loading indicator

      const response = await fetch(highQualityURL);
      const reader = response.body.getReader();
      const contentLength = +response.headers.get("Content-Length");
      let receivedLength = 0;
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        receivedLength += value.length;
        setDownloadProgress((receivedLength / contentLength) * 100);
      }

      const blob = new Blob(chunks);
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${videos[currentIndex]}.mp4`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      setDownloadProgress(null); // Hide progress after completion
    } catch (error) {
      console.error("Download failed:", error);
      setDownloadProgress(null);
    }
  };

  return (
    <div className="video-wrapper">
      <div className="video-container">
        <AnimatePresence mode="wait">
          {lowQualityURL ? (
            <motion.video
              key={lowQualityURL}
              className="video-player"
              controls
              autoPlay
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.2}
              onDragStart={() => setIsDragging(true)}
              onDragEnd={(event, info) => {
                setIsDragging(false);
                handleDragEnd(event, info);
              }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
              <source src={lowQualityURL} type="video/mp4" />
              Your browser does not support the video tag.
            </motion.video>
          ) : (
            <img
              src="https://via.placeholder.com/300x200"
              alt="Loading video..."
              className="video-placeholder"
            />
          )}
        </AnimatePresence>

        <div className="controls">
          <IconButton onClick={handlePrev} className="nav-button">
            <ArrowBack />
          </IconButton>

          <div style={{ position: "relative" }}>
            {highQualityURL && (
              <Button
                variant="contained"
                color="primary"
                className="download-button"
                onClick={handleDownload}
                disabled={downloadProgress !== null} // Disable while downloading
              >
                {downloadProgress !== null ? "Downloading..." : "Download Full Quality"}
              </Button>
            )}

            {downloadProgress !== null && (
              <CircularProgress
                size={24}
                thickness={5}
                variant="determinate"
                value={downloadProgress}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </div>

          <IconButton onClick={handleNext} className="nav-button">
            <ArrowForward />
          </IconButton>
        </div>

        <div className="thumbnails">
          {videos.map((video, index) => (
            <img
              key={index}
              src={thumbnails[video] || "https://via.placeholder.com/100x75"}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${index === currentIndex ? "active" : ""}`}
              onClick={() => {
                if (!isDragging) setCurrentIndex(index);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Videos;
