import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './Pages/stylesheets/Navbar.css';
import MiniLogo from './Pages/assets/MiniLogo.webp';
import ClickableImage from './Pages/components/ClickableImage';

export default function Navbar() {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isPress, setIsPress] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleGalleryDropdown = () => {
    setIsGalleryOpen(!isGalleryOpen);
  };

  const togglePressDropdown = () => {
    setIsPress(!isPress);
  };

  const handleBookingsClick = () => {
    navigate('/bookings');
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let margintransition = scrollY < 450 ? `${Math.max(2 - scrollPosition * 0.5, 0)}%` : 0
  let positiontransition = scrollY < 450 ? '' : 'fixed'
  let top = scrollY < 450 ? 0 : 20

  const [color, setColor] = useState("white");

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);

      const target = document.getElementById("WhereWeAt");
      if (target) {
        const targetPosition = target.getBoundingClientRect().top + window.scrollY;
        setColor(window.scrollY >= targetPosition ? "black" : "white");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className="navbar" style={{ marginTop: margintransition, position: positiontransition, top: top }}>
      <div className="navbar-logo">
        <Link to="/" id='MiniLogoArea'>
          <ClickableImage src={MiniLogo} alt="Logo" />
        </Link>
      </div>
      <ul className="navbar-links" id={location.pathname === '/UpcomingEvents' ? 'upcomingnav' : ''}>
        <li>
          <Link to="/about" style={{color:color }}>ABOUT</Link>
        </li>
        <li className="dropdown">
          <span onClick={toggleGalleryDropdown} className="dropdown-toggle" style={{color:color }}>
            GALLERY <FontAwesomeIcon icon={isGalleryOpen ? faChevronUp : faChevronDown} className="dropdown-icon" />
          </span>
          <ul style={{color:color }} className={`dropdown-menu ${isGalleryOpen ? 'show' : ''}`}>
            <li>
              <Link to="/LivePictures">Live Photos</Link>
            </li>
            <li>
              <Link to="/StudioPictures">Studio Photos</Link>
            </li>
            <li>
              <Link to="/Videos">Videos</Link>
            </li>
          </ul>
        </li>
        <li className="dropdown">
          <span onClick={togglePressDropdown} className="dropdown-toggle" style={{ textWrap: 'nowrap', color:color}}>
            RELEASES <FontAwesomeIcon icon={isPress ? faChevronUp : faChevronDown} className="dropdown-icon" />
          </span>
          <ul className={`dropdown-menu ${isPress ? 'show' : ''}`} id='dropdown-menu' >
            <li>
              <Link to="/PressCoverage">Originals</Link>
            </li>
            <li>
              <Link to="/PressCoverage#remixes">Remixes</Link>
            </li>
            <li>
              <Link to="/PressCoverage#articles">Press</Link>
            </li>
          </ul>
        </li>

        <li>
          <a onClick={handleBookingsClick} style={{color:color }}>BOOKINGS</a>
        </li>
        {/* <li>
          <Link to="/Shop">SHOP</Link>
        </li> */}
      </ul>
    </nav>
  );
}
