import React, { useState, useRef } from "react";
import { Menu, MenuItem, Tooltip } from "@mui/material";

const ClickableImage = ({ src, alt, className, id, style }) => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const imgRef = useRef(null);
    let touchTimeout = null;

    const handleImageClick = () => {
        if (window.location.pathname !== "/") {
            window.location.href = "/";
        }
    };


    const handleContextMenu = (event) => {
        event.preventDefault();
        setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = src;
        link.download = alt || "downloaded-image";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        handleCloseMenu();
    };

    const handleTouchStart = () => {
        touchTimeout = setTimeout(() => {
            setMenuAnchor(imgRef.current);
        }, 600);
    };

    const handleTouchEnd = () => {
        if (touchTimeout) {
            clearTimeout(touchTimeout);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip
                title="Right-click to download"
                arrow
                componentsProps={{
                    tooltip: {
                        style: { display: menuAnchor ? 'none' : 'block' }
                    }
                }}
            >
                <img
                    ref={imgRef}
                    src={src}
                    alt={alt}
                    className={`cursor-pointer transition-opacity duration-300 hover:opacity-70 ${className}`}
                    onClick={handleImageClick}
                    onContextMenu={handleContextMenu}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    id={id}
                    style={style}
                />
            </Tooltip>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={handleDownload}>Download</MenuItem>
            </Menu>
        </div>
    );
};

export default ClickableImage;
