import React, { useEffect, useState } from 'react';
import '../stylesheets/LinkButton.css';


export default function LinkButton({ link, text, left, id, textcolor, onClick, top }) {
  const [topPosition, setTopPosition] = useState('');
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop > lastScrollTop && currentScrollTop > 200) {
        // After scrolling down 200px, move to -30%
        setTopPosition('-30%');
      } else if (currentScrollTop <= 200) {
        // Scroll down/up behavior between 80% and 20%
        setTopPosition('');
      }

      lastScrollTop = currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = () => {
  };

  const combinedOnClick = (event) => {
    handleClick();
    if (onClick) {
      onClick(event);
    }
  };

  top = top != null ? top : topPosition;
  return (
    <a
      onClick={combinedOnClick}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      id={id}
      style={{ position: 'absolute', top: top, transition: 'top 0.3s ease', left: left, cursor: 'pointer', WebkitUserSelect:'none'}}
    >
      <h4 style={{ color: textcolor }} id='LinkButtonText'>{text}</h4>
    </a>
  );
}
