import { ref, getDownloadURL } from "firebase/storage";

export const fetchVideoURLs = async (storage, videoName) => {
  try {
    const lowQualityRef = ref(storage, `videos/low_quality/${videoName}.mp4`);
    const highQualityRef = ref(storage, `videos/high_quality/${videoName}.mp4`);

    const [lowQualityURL, highQualityURL] = await Promise.all([
      getDownloadURL(lowQualityRef),
      getDownloadURL(highQualityRef),
    ]);

    return { lowQualityURL, highQualityURL };
  } catch (error) {
    console.error("Error fetching video URLs:", error);
    return { lowQualityURL: null, highQualityURL: null };
  }
};

export const fetchThumbnails = async (storage, videos) => {
  try {
    const urls = {};
    await Promise.all(
      videos.map(async (video) => {
        const thumbnailRef = ref(storage, `videos/thumbnails/${video}.jpg`);
        urls[video] = await getDownloadURL(thumbnailRef);
      })
    );
    return urls;
  } catch (error) {
    console.error("Error fetching thumbnails:", error);
    return {};
  }
};
