import React, { useRef, useEffect, useState } from 'react';
import Globe from 'react-globe.gl';
import '../stylesheets/Components.css'
import { IoIosPause, IoIosPlayCircle } from 'react-icons/io';

const GlobeComponent = () => {
  const globeEl = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let GlobeSize = windowWidth < 768 ? windowWidth * 0.825 : windowWidth / 3;
  if (windowWidth > 1920) {
    GlobeSize = 1024
  }

  if (windowWidth > 1024 && windowWidth < 1512) {
    GlobeSize = windowWidth / 2
  }

  const markerSvg = `
    <svg viewBox="-4 0 36 36" width="14" height="14">
      <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
      <circle fill="black" cx="14" cy="14" r="7"></circle>
    </svg>
  `;

  const labelData = [
    // {
    //   lat: 25.276987, // Latitude for Qatar
    //   lng: 51.520008, // Longitude for Qatar
    //   label: 'Qatar',
    //   color: 'red',
    //   size: 20,
    // },
    {
      lat: 26.8206,
      lng: 26.8025,
      label: 'Egypt',
      color: 'red',
      size: 20
    },
    // {
    //   lat: 23.8859,  // Latitude for Saudi Arabia
    //   lng: 45.0792,  // Longitude for Saudi Arabia
    //   label: 'Saudi Arabia',
    //   color: 'red',
    //   size: 20
    // }
    // {
    //   lat: 52.3555,
    //   lng: -1.1743,
    //   label: 'England',
    //   color: 'white',
    //   size: 20 
    // },
    // {
    //   lat: 46.6034,
    //   lng: 2.2137,
    //   label: 'France',
    //   color: 'white',
    //   size: 20 
    // },
    // {
    //   lat: 41.8719,
    //   lng: 12.5674,
    //   label: 'Italy',
    //   color: 'white',
    //   size: 20 
    // }
  ];

  const [isInteractive, setIsInteractive] = useState(false);

  const toggleInteractivity = () => {
    setIsInteractive(!isInteractive);
    if (globeEl.current) {
      globeEl.current.controls().enabled = !globeEl.current.controls().enabled;
    }
  };

  useEffect(() => {
    if (globeEl.current) {
      globeEl.current.pointOfView({ lat: 16.8206, lng: 30.8025, altitude: 1.75 });
      globeEl.current.controls().enabled = false;  // Start off as static
    }
  }, []);

  return (
    <div className="Globe-container" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <a
        onClick={toggleInteractivity}
        style={{
          zIndex: 1,
          padding: '2.5px',
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: '#282c3480',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontWeight: 900,
          userSelect: 'none',
          marginBottom: windowWidth > 1024 ? -33 : -10
        }}
      >
        {isInteractive ?
          <div style={{ display: 'flex', gap: '2.5%', alignItems: 'center' }}>
            <h4 style={{ padding: 0, margin: 0, color: 'white', marginRight: 5, fontSize: '1.25svh', marginLeft: 5 }}>Stop</h4>
            <IoIosPause size={20} />
          </div>
          :
          <div style={{ display: 'flex', gap: '2.5%', alignItems: 'center' }}>
            <h4 style={{ padding: 0, margin: 0, color: 'white', marginRight: 5, fontSize: '1.25svh', marginLeft: 5, textWrap: 'nowrap' }}>Move Globe</h4>
            <IoIosPlayCircle size={20} />
          </div>
        }
        {/* {isInteractive ? 'Deactivate Globe' : 'Activate Globe'} */}
      </a>
      <Globe
        ref={globeEl}
        width={GlobeSize}
        height={GlobeSize}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        backgroundColor="rgba(0, 0, 0, 0)"
        htmlElementsData={labelData}
        htmlElement={d => {
          const el = document.createElement('div');
          el.innerHTML = `
            ${markerSvg}
            <div style="color: white; font-size: 12px; text-align: center; margin-top: -5px;">
              ${d.label}
            </div>
          `;
          el.style.color = d.color;
          el.style.width = `${d.size}px`;
          el.style['pointer-events'] = 'auto';
          el.style.cursor = 'pointer';
          el.onclick = () => console.info(d);
          return el;
        }}
      />
    </div>
  );
};

export default GlobeComponent;
