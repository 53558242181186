import React, { useEffect, useRef, useState } from 'react';
import './stylesheets/LivePictures.css';
import { Helmet } from 'react-helmet-async';

const LivePic1 = "/livepics/disco-misr-live-performance.png";
const LivePic2 = "/livepics/disco-misr-redbull-performance.png";
const LivePic3 = "/livepics/disco-misr-live-performance-recent.png";
const LivePic4 = "/livepics/disco-misr-live-crowd.png";
const LivePic6 = "/livepics/disco-misr-with-crowd-live-performance.webp";
const LivePic7 = "/livepics/disco-misr-visuals-live-performance.webp";
const LivePic8 = "/livepics/disco-misr-sahel-live-performance.webp";
const LivePic10 = "/livepics/disco-misr-redbull-live-performance.webp";
const LivePic11 = "/livepics/disco-misr-live-visuals-performance.webp";
const LivePic12 = "/livepics/disco-misr-2025-live.jpg";
const LivePic13 = "/livepics/disco-misr-live-2025.jpg";
const LivePic14 = "/livepics/disco-misr-visuals.jpg";

const ThumbnailNavigation = ({ images, activeIndex, onThumbnailClick }) => {
  return (
    <div className="thumbnail-wrapper">
      {images.map((image, index) => (
        <div
          key={index}
          className={`thumbnail ${index === activeIndex - 1 ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
          onClick={() => onThumbnailClick(index + 1)}
        ></div>
      ))}
    </div>
  );
};

export default function LivePictures() {
  const wrapperRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      const wrapper = wrapperRef.current;
      const sectionWidth = wrapper.clientWidth;
      const newIndex = Math.round(wrapper.scrollLeft / sectionWidth);
      const currentScrollLeft = wrapper.scrollLeft;
      const maxScroll = wrapper.scrollWidth - wrapper.clientWidth;
      const scrollProgress = currentScrollLeft / maxScroll;
      const moveDistance = scrollProgress * 3000; // Adjust distance based on scroll progress

      setActiveIndex(newIndex);

      const words = wrapper.querySelectorAll('.word');
      words.forEach((word, index) => {
        const angle = index * (360 / words.length);
        const radians = (angle * Math.PI) / 90;
        const x = (Math.cos(radians) * moveDistance) * 1.25;
        const y = (Math.sin(radians) * moveDistance) * 1.25;

        // Adjust opacity to start disappearing 5% after scroll
        if (scrollProgress > 0.05) {
          word.style.opacity = Math.max(1 - (scrollProgress - 0.05) * 2, 0);
        } else {
          word.style.opacity = 1;
        }

        word.style.transform = `translate(${x * 3}px, ${y * 3}px)`;
      });
    };

    const wrapper = wrapperRef.current;
    wrapper.addEventListener('scroll', handleScroll);
    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const startDrag = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - wrapperRef.current.offsetLeft);
    setScrollLeft(wrapperRef.current.scrollLeft);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - wrapperRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    wrapperRef.current.scrollLeft = scrollLeft - walk;
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  const scrollToLeft = () => {
    const newIndex = Math.max(activeIndex - 1, 0);
    setActiveIndex(newIndex);
    const sectionWidth = wrapperRef.current.clientWidth;
    wrapperRef.current.scrollTo({ left: sectionWidth * newIndex, behavior: 'smooth' });
  };

  const scrollToRight = () => {
    const newIndex = Math.min(activeIndex + 1, images.length - 1);
    setActiveIndex(newIndex);
    const sectionWidth = wrapperRef.current.clientWidth;
    wrapperRef.current.scrollTo({ left: sectionWidth * newIndex, behavior: 'smooth' });
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    const sectionWidth = wrapperRef.current.clientWidth;
    wrapperRef.current.scrollTo({ left: sectionWidth * index, behavior: 'smooth' });
  };

  const images = [LivePic12, LivePic13, LivePic14, LivePic1, LivePic2, LivePic3, LivePic4, LivePic6, LivePic7, LivePic8, LivePic10, LivePic11];
  const altsforimages = [
    "Disco Misr Live in Concert - Capturing the Energy and Music | discomisr.com",
    "Crowd Cheering at a Disco Misr Live Event | discomisr.com",
    "On Stage with Disco Misr - Live Performance Highlights | discomisr.com",
    "Close-up of Disco Misr Performing Live - Music and Passion | discomisr.com",
    "Stage Lights and Sound - Disco Misr Concert Experience | discomisr.com",
    "Fans Enjoying a Disco Misr Live Show - Immersed in Music | discomisr.com",
    "Band Members Engaging with the Audience - Disco Misr Live | discomisr.com",
    "Behind the Scenes at a Disco Misr Concert - Live Performance Setup | discomisr.com",
    "Epic Moment from a Disco Misr Live Set - Music in Motion | discomisr.com",
    "DJ Set and Crowd Interaction - Disco Misr Live Experience | discomisr.com",
    "Live Music Festival Featuring Disco Misr - High-Energy Performance | discomisr.com",
    "Finale Moment from a Disco Misr Concert - A Night to Remember | discomisr.com"
  ];

  return (
    <>
      <Helmet>
        <title>Disco Misr - Live Photos</title>
        <meta name="description" content="Experience Disco Misr live! Browse our best moments captured from concerts and events." />
        <meta property="og:title" content="Disco Misr - Live Photos" />
        <meta property="og:description" content="Relive the energy of our concerts through exclusive live photos." />
        <meta property="og:image" content="https://discomisr.com/livepics/disco-misr-live-2025.jpg" />
        <meta property="og:url" content="https://discomisr.com/LivePictures" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "ImageGallery",
            "name": "Disco Misr | Live Pictures",
            "image": images.map((src) => ({
              "@type": "ImageObject",
              "url": `https://discomisr.com${src}`,
              "caption": `Description for ${src.split("/").pop()}`
            }))
          })}
        </script>
      </Helmet>
      <div
        id='LivePicturesWrap'
        ref={wrapperRef}
        onMouseDown={startDrag}
        onMouseMove={onDrag}
        onMouseUp={stopDrag}
        onMouseLeave={stopDrag}
      >
        <div className="section">
          <div className="word">OUR</div>
          <div className="word">LIVE</div>
          <div className="word">PICTURES</div>
          {images.length > 0 && (
            <div className="next-image-overlay" onClick={scrollToRight}>
              <div className="next-image-preview" style={{ backgroundImage: `url(${images[0]})` }}></div>
              <div className="next-image-text">Swipe right for next image</div>
            </div>
          )}
          <div className="arrow-buttons">
            <button className="arrow left-arrow" style={{ display: 'none' }} onClick={scrollToLeft}>&#8592;</button>
            <button className="arrow right-arrow" onClick={scrollToRight}>&#8594;</button>
          </div>
        </div>
        {images.map((image, index) => (
          <div className="section" key={index} style={{ backgroundImage: `url(${image})` }}>
            <img className='imgsection' key={index} src={image} alt={altsforimages[index]} loading="lazy" />
            {index < images.length - 1 && (
              <div className="next-image-overlay" onClick={scrollToRight}>
                <div className="next-image-preview" style={{ backgroundImage: `url(${images[index + 1]})` }}></div>
                <div className="next-image-text">Swipe right for next image</div>
              </div>
            )}
            <div className="arrow-buttons" style={{ display: 'none' }}>
              <button className="arrow left-arrow" onClick={scrollToLeft}>&#8592;</button>
              <button className="arrow right-arrow" style={{ display: index > images.length - 2 ? 'none' : 'block' }} onClick={scrollToRight}>&#8594;</button>
            </div>
            <ThumbnailNavigation images={images} activeIndex={activeIndex} onThumbnailClick={handleThumbnailClick} />
          </div>
        ))}
      </div>
    </>
  );

}
