import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDENxiRPAZNlQHwIU9l8OgXk19dOMg6tI0",
  authDomain: "discomisr-d5eb7.firebaseapp.com",
  projectId: "discomisr-d5eb7",
  storageBucket: "discomisr-d5eb7.firebasestorage.app",
  messagingSenderId: "324432421713",
  appId: "1:324432421713:web:560c1f0a2c8768dbe02970",
  measurementId: "G-Q9EK0R04J9"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
