import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinkButton from './LinkButton';
import { Button, fabClasses } from '@mui/material';
import { useSwipeable } from "react-swipeable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const getExpiryTime = (eventDate) => {
    const expiryDate = new Date(eventDate);
    expiryDate.setHours(23, 59, 59, 999); // Set to 11:59:59 PM
    return expiryDate; // Return a Date object instead of a string
};


const eventDetails = {
    barcelona: {
        name: "Disco Misr Live in Barcelona",
        date: 'THURSDAY, June 12, 2025',
        link: "https://www.billetweb.fr/disco-misr-live-in-barcelona1",
        sublinktext: false,
        expiry: getExpiryTime('2025-06-12', 'Europe/Madrid')
    },
    amsterdam: {
        name: "Disco Misr Live in Amsterdam",
        date: 'FRIDAY, June 13, 2025',
        link: "https://www.billetweb.fr/disco-misr-live-in-ams",
        sublinktext: false,
        expiry: getExpiryTime('2025-06-13', 'Europe/Amsterdam')
    },
    parisFestival: {
        name: "Transit Festival - Paris",
        date: 'SATURDAY, June 14, 2025',
        link: "https://www.billetweb.fr/transit-festival",
        sublinktext: false,
        expiry: getExpiryTime('2025-06-14', 'Europe/Paris')
    },
    berlinFestival: {
        name: "Transit Festival - Berlin",
        date: 'SUNDAY, June 15, 2025',
        link: "https://www.billetweb.fr/transit-festival-berlin",
        sublinktext: false,
        expiry: getExpiryTime('2025-06-15', 'Europe/Berlin')
    },
    brussels: {
        name: "Disco Misr Live in Brussels",
        date: 'THURSDAY, June 19, 2025',
        link: "https://www.billetweb.fr/disco-misr-live-in-brussels",
        sublinktext: false,
        expiry: getExpiryTime('2025-06-19', 'Europe/Brussels')
    },
    malmo: {
        name: "Disco Misr Live in Malmo",
        date: 'FRIDAY, June 20, 2025',
        link: "https://www.billetweb.fr/disco-misr-live-in-malmo",
        sublinktext: false,
        expiry: getExpiryTime('2025-06-20', 'Europe/Stockholm')
    },
    cologne: {
        name: "Disco Misr Live in Köln",
        date: 'SATURDAY, June 21, 2025',
        link: "https://www.billetweb.fr/disco-misr-live-in-koln",
        sublinktext: false,
        expiry: getExpiryTime('2025-06-21', 'Europe/Berlin')
    },
    italy: {
        name: "Disco Misr Live in Italy",
        date: 'FRIDAY, June 27, 2025',
        link: "https://www.livenation.it/en/disco-misr-tickets-adp1578868",
        sublinktext: false,
        expiry: getExpiryTime('2025-06-27', 'Europe/Rome')
    }
};



const WorldTourModal = ({ open, handleClose }) => {
    const [modalWidth, setModalWidth] = useState(400);

    useEffect(() => {
        const updateWidth = () => {
            setModalWidth(window.innerWidth < 400 ? window.innerWidth * 0.9 : 400);
        };

        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: modalWidth,
        bgcolor: '#1e1e1e',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleEventClick = (event) => {
        console.log("Checking event:", event.name, "Expiry:", event.expiry, "Current Time:", new Date());
        if (event.expiry && new Date() > event.expiry) return;
        if (window.gtag) {
            window.gtag('event', 'click', {
                event_category: 'Button',
                event_label: event.name,
                value: 1
            });
        }
        window.open(event.link, '_blank');
    };

    const [page, setPage] = useState(0);
    const eventsPerPage = 4;
    const eventList = Object.values(eventDetails).filter(event => !event.expiry || new Date() <= event.expiry);
    const totalPages = Math.ceil(eventList.length / eventsPerPage);

    const handleNext = () => setPage(prev => (prev + 1) % totalPages);
    const handlePrev = () => setPage(prev => (prev - 1 + totalPages) % totalPages);

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            style={{ zIndex: 9999 }}
            {...handlers}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            textAlign: 'center',
                            marginBottom: 2,
                            fontWeight: 'bold',
                            color: 'white'
                        }}
                    >
                        WORLD TOUR
                    </Typography>

                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                            {eventList.slice(page * eventsPerPage, (page + 1) * eventsPerPage).map(event => (
                                <Button
                                    key={event.name}
                                    variant="contained"
                                    sx={{
                                        background: 'white',
                                        color: 'black',
                                        width: '100%',
                                        fontSize: '16px',
                                        ':hover': {
                                            background: 'linear-gradient(45deg, #A09ADC 20%, rgba(183, 232, 207, 1) 60%)',
                                            fontWeight: 'bold',
                                        },
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    onClick={() => handleEventClick(event)}
                                >
                                    {event.name}
                                    <br />
                                    {event.sublinktext && (
                                        <span style={{ fontFamily: "'Tajawal', 'Cairo', 'Noto Kufi Arabic', sans-serif", fontSize: '12px', fontStyle: 'italic', textTransform: "none" }}>
                                            For reservations DM <span style={{ fontWeight: 700 }}>@babbostaverna</span>
                                        </span>
                                    )}
                                    <span style={{ fontFamily: "'Tajawal', 'Cairo', 'Noto Kufi Arabic', sans-serif", fontSize: '12px' }}>
                                        {event.date}
                                    </span>
                                </Button>
                            ))}
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2, gap: 2 }}>
                        <ArrowBackIosIcon sx={{ cursor: "pointer", color: "white" }} onClick={handlePrev} />
                        <Typography sx={{ color: "white" }}>{page + 1} / {totalPages}</Typography>
                        <ArrowForwardIosIcon sx={{ cursor: "pointer", color: "white" }} onClick={handleNext} />
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default WorldTourModal;
